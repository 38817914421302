<template>
    <div class="order-details">
        <div class="col-title-order">
            <span class="title-order"> {{ operationInfos.order.orderNumber }} </span>
        </div>

        <b-col>
            <b-row class="card-detail">
                <b-col md="12">
                    <span class="title-card"> {{ $t("orderView.Order") }} </span>
                    <b-row>
                        <b-col md="4" sm="4">
                            <div class="item-card">
                                <span class="title-item-card">
                                    {{ $t("orderView.Quantity") }}
                                </span>
                                <span class="info">
                                    {{ Math.ceil(operationInfos.order.quantity) }}
                                </span>
                            </div>
                        </b-col>
                        <b-col md="4" sm="4">
                            <div class="item-card">
                                <span class="title-item-card">
                                    {{ $t("orderView.Priority") }}
                                </span>
                                <span class="info">
                                    {{ Math.ceil(operationInfos.order.priority) }}
                                </span>
                            </div>
                        </b-col>

                        <b-col md="4" sm="4">
                            <div class="item-card">
                                <span class="title-item-card"> {{ $t("orderView.DueDate") }} </span>
                                <span class="info">
                                    {{ getFormatedDate(operationInfos.order.deliveryDate) }}
                                </span>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row class="card-detail">
                <b-col md="12">
                    <span class="title-card"> {{ $t("orderView.Operation") }} </span>
                    <b-row>
                        <b-col md="4" sm="4">
                            <div class="item-card">
                                <span class="title-item-card">
                                    {{ $t("orderView.OperationNumber") }}
                                </span>
                                <span class="info"> {{ operationInfos.operation.number }} </span>
                            </div>
                        </b-col>
                        <b-col md="4" sm="4">
                            <div class="item-card">
                                <span class="title-item-card">
                                    {{ $t("orderView.OperationName2") }}
                                </span>
                                <span class="info"> {{ operationInfos.operation.name }} </span>
                            </div>
                        </b-col>
                        <b-col md="4" sm="4">
                            <div class="item-card">
                                <span class="title-item-card">
                                    {{ $t("orderView.ProductCode") }}
                                </span>
                                <span class="info">
                                    {{ operationInfos.operation.productCode }}
                                </span>
                            </div>
                        </b-col>
                        <b-col md="4" sm="4">
                            <div class="item-card">
                                <span class="title-item-card">
                                    {{ $t("orderView.ProductName") }}
                                </span>
                                <span class="info">
                                    {{ operationInfos.operation.productName }}
                                </span>
                            </div>
                        </b-col>
                        <b-col md="4" sm="4">
                            <div class="item-card">
                                <span class="title-item-card">
                                    {{ $t("orderView.Resource") }}
                                </span>
                                <span class="info">
                                    {{
                                        handleFormatListToFormattedString(
                                            operationInfos.operation.resourcesNames
                                        )
                                    }}
                                </span>
                            </div>
                        </b-col>

                        <b-col md="4" sm="4">
                            <div class="item-card">
                                <span class="title-item-card">
                                    {{ $t("orderView.Workcenter") }}
                                </span>
                                <span class="info">
                                    {{ operationInfos.operation.workcenterName }}
                                </span>
                            </div>
                        </b-col>
                        <b-col md="4" sm="4">
                            <div class="item-card">
                                <span class="title-item-card">
                                    {{ $t("orderView.ForecastOperationSetup") }}
                                </span>
                                <span class="info">
                                    {{ getFormatedDate(operationInfos.operation.setupStart) }}
                                </span>
                            </div>
                        </b-col>

                        <b-col md="4" sm="4">
                            <div class="item-card">
                                <span class="title-item-card">
                                    {{ $t("orderView.ForecastOperationStart") }}
                                </span>
                                <span class="info">
                                    {{ getFormatedDate(operationInfos.operation.startTime) }}
                                </span>
                            </div>
                        </b-col>
                        <b-col md="4" sm="4">
                            <div class="item-card">
                                <span class="title-item-card">
                                    {{ $t("orderView.ForecastOperationEnd") }}
                                </span>
                                <span class="info">
                                    {{ getFormatedDate(operationInfos.operation.endTime) }}
                                </span>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>

            <b-row class="card-detail">
                <b-col md="12">
                    <span class="title-card"> {{ $t("OperationProgress.Attributes") }} </span>
                    <b-row>
                        <b-col
                            v-for="(item, index) in formattedAttributes"
                            :key="'custom-attribute-apontamento-' + index"
                            md="4"
                            sm="4"
                        >
                            <div class="item-card">
                                <span class="title-item-card"> {{ item.label }} </span>
                                <span class="info"> {{ item.value }} </span>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row class="card-detail">
                <b-col md="12">
                    <span class="title-card"> {{ $t("orderView.Observations") }} </span>
                    <b-row>
                        <b-col md="12">
                            <div class="item-card">
                                <span
                                    class="title-item-card"
                                    v-html="operationInfos.operation.observations"
                                />
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <b-row class="card-detail">
                <b-col md="12">
                    <span class="title-card links">Links</span>
                    <div class="itens-links">
                        <a
                            target="_blank"
                            @click="openUrl(link.url, link.type)"
                            v-for="(link, index) in linksList"
                            :key="index"
                            class="info card-files-links"
                        >
                            <pdfFile v-if="link.extension == 'pdf'" />
                            <wordFile
                                v-else-if="
                                    ['doc', 'docm', 'docx', 'dot', 'dotx'].includes(link.extension)
                                "
                            />
                            <mp3File v-else-if="['mp3', 'm4a'].includes(link.extension)" />
                            <xmlFile
                                v-else-if="
                                    [
                                        'xla',
                                        'xlm',
                                        'xls',
                                        'xlsm',
                                        'xlsx',
                                        'xlt',
                                        'xltm',
                                        'xltx'
                                    ].includes(link.extension)
                                "
                            />
                            <textFile v-else-if="link.extension == 'txt'" />
                            <mp4File v-else-if="['mp4', 'mpeg', 'wmv'].includes(link.extension)" />
                            <imgFile
                                v-else-if="
                                    ['png', 'jpeg', 'jpg'].includes(link.extension) ||
                                    link.type == 'base64'
                                "
                            />
                            <zipFile v-else-if="['zip', 'rar'].includes(link.extension)" />
                            <webFile v-else />
                            <span :id="`tooltip-link-${index}`">
                                {{ link.name }}.{{ link.extension }}
                            </span>
                            <b-tooltip
                                :target="`tooltip-link-${index}`"
                                triggers="hover blur"
                                positioning="top"
                                placement="top"
                                boundary-padding="0"
                                class="tooltip-link"
                            >
                                <div class="info-tooltip-link">
                                    {{ link.name }}.{{ link.extension }}
                                </div>
                            </b-tooltip>
                        </a>
                    </div>
                </b-col>
            </b-row>
        </b-col>
    </div>
</template>

<script>
    import pdfFile from "@/assets/images/pages/pdf-file.svg";
    import webFile from "@/assets/images/pages/web-file.svg";
    import wordFile from "@/assets/images/pages/word-file.svg";
    import mp3File from "@/assets/images/pages/mp3-file.svg";
    import xmlFile from "@/assets/images/pages/xml-file.svg";
    import textFile from "@/assets/images/pages/text-file.svg";
    import mp4File from "@/assets/images/pages/mp4-file.svg";
    import imgFile from "@/assets/images/pages/img-file.svg";
    import zipFile from "@/assets/images/pages/zip-file.svg";
    import { BRow, BCol, BTooltip } from "bootstrap-vue";
    import { mapMutations } from "vuex";
    import moment from "moment";

    export default {
        components: {
            BRow,
            BCol,
            BTooltip,
            pdfFile,
            webFile,
            wordFile,
            mp3File,
            xmlFile,
            textFile,
            mp4File,
            imgFile,
            zipFile
        },

        data() {
            return {
                prefix: localStorage.getItem("prefix") || this.$router.currentRoute.params.prefix,
                currentSite: this.$cookies.get("userInfo").currentSite,
                attributesFields: [],
                linksList: [],
                operationInfos: [],
                formattedAttributes: []
            };
        },
        async created() {
            await this.handleInitialRouteCalls();
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            handleFormatListToFormattedString(list) {
                if (!list) return "";

                const listLocal = [...list];

                if (!listLocal.length) return "";

                if (listLocal.length === 1) return listLocal[0];

                const allButLast = listLocal.slice(0, -1);

                if (!allButLast.length) return "";

                const formattedResources = `${allButLast.join(", ")} ${this.$t(
                    "ProductionProgress.And"
                )} ${listLocal.at(-1)}`;

                return formattedResources;
            },
            formatAttributes(attributes) {
                const formattedList = attributes.map((att) => {
                    if (att.industrialStructParameterType === "select_multiple") {
                        return {
                            ...att,
                            value: this.handleFormatListToFormattedString(att.value)
                        };
                    }

                    if (att.industrialStructParameterType === "datepicker") {
                        return {
                            ...att,
                            value: this.getFormatedDate(att.value)
                        };
                    }

                    if (att.industrialStructParameterType === "range_dates") {
                        return {
                            ...att,
                            value:
                                this.getFormatedDate(att.value[0]) +
                                " - " +
                                this.getFormatedDate(att.value[1])
                        };
                    }

                    if (att.industrialStructParameterType === "checkbox") {
                        return {
                            ...att,
                            value: att.value ? "Sim" : "Não"
                        };
                    }

                    if (
                        att.industrialStructParameterType === "photo" ||
                        att.industrialStructParameterType === "location"
                    ) {
                        return {
                            ...att,
                            value: ""
                        };
                    }

                    return att;
                });

                this.formattedAttributes = formattedList;
            },

            openUrl(url, type) {
                if (type == "base64") {
                    var win = window.open();
                    win.document.write(
                        '<iframe src="' +
                            url +
                            '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
                    );
                } else {
                    window.open(url, "_blank");
                }
            },
            async getLinks() {
                try {
                    const response = await this.$http.get(
                        `/api/${this.prefix}/order/links?site_guid=${this.currentSite}&preactor_order_id=${this.$router.currentRoute.params.order_id}`
                    );
                    this.linksList = response.data;
                } catch (error) {
                    console.error(error);
                }
            },
            getFormatedDate(date) {
                if (!date) return "";

                let language = this.$cookies.get("userInfo").language;

                if (language === "pt-BR") return moment(date).format("DD-MM-YYYY HH:mm");
                else return moment(date).format("MM-DD-YYYY HH:mm");
            },
            async getOrderInfos() {
                try {
                    const response = await this.$http2.get(
                        `/api/mysfc/order/operation/${this.$router.currentRoute.params.operation_id}?siteId=${this.currentSite}`
                    );

                    const data = response.data.data || null;

                    if (data?.operationInfos?.observations?.includes("table")) {
                        const replacedObservations = data?.operations?.observations
                            ?.replaceAll(
                                "<table",
                                '<div style="overflow: hidden; overflow-x: scroll"><table'
                            )
                            ?.replaceAll("</table>", "</table></div>");

                        data.operation.observations = replacedObservations;
                    }

                    this.operationInfos = data;
                    this.attributesFields = data.operation.attributes;
                    this.formatAttributes(data.operation.attributes);
                } catch (error) {
                    console.error(error);
                }
            },
            async handleInitialRouteCalls() {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                try {
                    await Promise.all([this.getOrderInfos(), this.getLinks()]);
                } finally {
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            }
        }
    };
</script>

<style lang="scss">
    @media (max-width: 480px) {
        .card-detail {
            .item-card {
                flex-direction: row !important;
                justify-content: space-between;
                gap: 4px;
                .title-item-card {
                    font-size: 12px !important;
                    line-height: 16px !important;
                }
                .info {
                    font-size: 14px !important;
                    line-height: 20px !important;
                }
            }
        }
    }

    .order-details {
        .col-title-order {
            margin-bottom: 16px !important;
            .title-order {
                font-weight: 600;
                font-size: 36px;
                line-height: 50px;
                color: #4c4541;
            }
        }

        .card-detail {
            margin-bottom: 24px;
            padding: 16px 2px;
            background: #ffffff;
            box-shadow: 0px 4px 24px rgba(58, 46, 38, 0.1);
            border-radius: 6px;

            .title-card {
                font-weight: 600;
                font-size: 18px;
                line-height: 26px;
                color: #4c4541;
            }
            .item-card {
                display: flex;
                flex-direction: column;
                margin-top: 16px;
                .title-item-card {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #998f8a;
                }
                .info {
                    word-break: break-word;
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 30px;
                    color: #4c4541;
                }
            }
            .itens-links {
                margin-top: 16px;
                .card-files-links {
                    border: 1px solid #cfc4be;
                    border-radius: 6px;
                    padding: 24px 16px;
                    margin-bottom: 16px !important;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    svg {
                        margin-right: 18px;
                    }

                    &:hover {
                        cursor: pointer;
                    }
                    span {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 26px;
                        color: #4c4541;
                    }
                }
            }
        }
    }
</style>
